<template lang="pug">
	section.static-content
		.static-content__bg(style="background-image: url('/img/map.svg')")
		.static-content__content(v-html="content.common_content")
		nuxt-link.static-content__button(:to="localePath('about-us') + '/'")
			span.text {{ $t('links.learnAbout') }}
			.logo
				img.lazyload(src="/img/img-preloader.svg"
											data-src="/img/main-logo.svg" 
											:alt="$t('header.logoImageTitle')"
											:title="$t('header.logoImageTitle')")
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.static-content {
  position: relative;
  margin: 90px 0 35px;
  padding: 95px 0 175px;

  @include mobile {
    margin: 0 0 30px;
    padding: 0 0 150px;
  }

  &__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    right: 50%;
    width: 100vw;
    height: 100%;
    background-color: $whiteColor;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: calc(-50vw - 100px);
    margin-right: calc(-50vw - 100px);

    @include tablet {
      top: 20%;
      height: 80%;
      margin-left: -50vw;
      margin-right: -50vw;
      background-size: cover;
    }

    @include mobile-small {
      top: 40%;
      height: 60%;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        $whiteColor 0%,
        rgba(255, 255, 255, 0.25) 9%,
        rgba(255, 240, 191, 0) 100%
      );
    }
  }

  &__content {
    width: 100%;
    max-width: 630px;
    margin-left: auto;
    margin-right: 140px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;

    @include tablet {
      margin-right: auto;
    }

    @include mobile {
      max-width: 100%;
    }

    h2 {
      position: relative;
      margin: 0;
      margin-bottom: 50px;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 2px;
      line-height: 1.4;
      text-transform: uppercase;

      @include tablet {
        margin-bottom: 25px;
      }

      @include mobile {
        font-size: 20px;
      }
    }

    p {
      max-width: 420px;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 130px;
    height: 130px;
    margin: 50px auto 0;
    padding: 30px 15px;
    border-radius: 50%;
    background-color: $yellowColor;

    @include mobile {
      width: 110px;
      height: 110px;
      padding: 20px 10px;
    }

    .text {
      margin-bottom: 10px;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: -0.36px;
      line-height: 1.8;
      text-align: center;
    }

    .logo {
      width: 100px;

      @include mobile {
        width: 80px;
      }
    }
  }
}

// Single product
.product .static-content {
  margin-top: 50px;

  @include tablet {
    margin-top: 0;
  }
}
</style>
