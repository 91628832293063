<template lang="pug">
	.front-page
		//- Categories section
		CategoriesSection

		//- Common sections
		template(v-if="commonSections")
			section.front-section(v-for="(section, index) in commonSections" :key="index")
				template(v-for="(block, index) in section")

					//- Full block
					template(v-if="index === 'full_block'")

						//- Mobile version of full block
						nuxt-link.mobile-block(:to="localePath({ name: 'product-category-slug', params: { slug: replaceUrl(block.link) } }) + '/'")
							img.lazyload.mobile-block__img(src='/img/img-preloader.svg'
																							:data-src="block.bg_mob"
																							:alt="block.title"
																							:title="block.title")
							h3.mobile-block__title {{ block.title }}
							.mobile-block__link
								span.text {{ block.link_text }}
								svg-icon.icon-arrow(name="arrow")

						//- Desktop version of full block
						.full-block
							.full-block__bg( :style="{ backgroundImage: `url(${block.bg})` }" )
							h2.full-block__title(:style="{ color: block.text_color }") {{ block.title }}
							nuxt-link.full-block__link(:to="localePath({ name: 'product-category-slug', params: { slug: replaceUrl(block.link) } }) + '/'")
								span.text(:style="{ color: block.text_color }") {{ block.link_text }}
								svg-icon.icon-arrow.icon-arrow--big(name="arrow" :style="{ fill: block.text_color }")

					//- Small block
					template(v-else)

						//- Mobile version of small block
						nuxt-link.mobile-block(:to="localePath({ name: 'product-category-slug', params: { slug: replaceUrl(block.link) } }) + '/'")
							img.lazyload.mobile-block__img(src='/img/img-preloader.svg'
																							:data-src="block.bg_mob"
																							:alt="block.title"
																							:title="block.title")
							h3.mobile-block__title {{ block.title }}
							.mobile-block__link
								span.text {{ block.link_text }}
								svg-icon.icon-arrow(name="arrow")

						//- Desktop version of small block
						nuxt-link.small-block(:to="localePath({ name: 'product-category-slug', params: { slug: replaceUrl(block.link) } }) + '/'")
							.lazyload.small-block__bg(:style="{ backgroundImage: `url('/img/img-preloader.svg')` }"
																				:data-bg="'url(' + block.bg + ')'" )
							h3.small-block__title {{ block.title }}
							.small-block__link
								span.text {{ block.link_text }}
								svg-icon.icon-arrow(name="arrow")

		//- Static content
		StaticContent(:content="staticContent")
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api/index'
import CategoriesSection from '@/components/partials/CategoriesSection'
import StaticContent from '@/components/partials/StaticContent'

export default {

	components: { 
		CategoriesSection,
		StaticContent
	},

	async asyncData({ app, store }) {
    const data = await api.getFrontPage(store.state.geoData.locale)

    return { page: data }
  },

  data() {
    return {
      page: {}
    }
  },

  head() {
    return {
      title: this.pageTitle,
      titleTemplate: null,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescr
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.metaDescr
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://api.sitelle.ua/wp-content/uploads/2019/06/1-4-of-66-3-e1562100221344.jpg'
        }
      ]
    }
  },

  computed: {
    ...mapState({
      staticContent: state => state.content.data
    }),

    pageTitle() {
      return this.page.yoast_meta.yoast_wpseo_title
    },

    metaDescr() {
      return this.page.yoast_meta.yoast_wpseo_metadesc
    },

    commonSections() {
      return this.page.acf.section
    }
	},
}
</script>

<style lang="scss">
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.front-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
  line-height: 1.35;

  @include laptop {
    margin-bottom: 80px;
  }

  @include tablet {
    margin-bottom: 50px;
  }

  @include mobile {
    display: block;
  }

  .full-block {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 620px;
    padding: 250px 0 250px 135px;

    @include laptop {
      padding: 180px 0 180px 50px;
    }

    @include tablet {
      padding: 120px 0;
      min-height: unset;
    }

		@include mobile {
			display: none;
		}

    &__bg {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      right: 50%;
      width: 100vw;
      height: 100%;
      margin-left: calc(-50vw - 100px);
      margin-right: calc(-50vw - 100px);
      background-color: $whiteColor;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include tablet {
        margin-left: -50vw;
        margin-right: -50vw;
      }

      &.full {
        margin-left: -50vw;
        margin-right: -50vw;
      }
    }

    &__title {
      margin-bottom: 24px;
      font-size: 72px;
      font-weight: 700;
      text-transform: uppercase;

      @include laptop {
        font-size: 42px;
      }

      @include tablet {
        margin-bottom: 15px;
        font-size: 40px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 600;

      @include laptop {
        font-size: 20px;
      }

      @include tablet {
        font-size: 18px;
      }

      svg {
        margin-left: 20px;
        fill: $blackColor;
        transition: all 0.3s;
      }

      &:hover {
        svg {
          transform: translateX(10px);
        }
      }
    }
  }

  .small-block {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-top: 120px;

    @include laptop {
      margin-top: 80px;
    }

    @include tablet {
      margin-top: 50px;
    }

		@include mobile {
			display: none;
		}

    &__bg {
      width: 100%;
      height: 700px;
      margin-bottom: 20px;
      background-color: $whiteColor;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include laptop {
        height: 550px;
      }

      @include tablet {
        height: 500px;
      }
    }

    &__title {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__link {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;

      svg {
        margin-left: 20px;
        fill: $blackColor;
        transition: all 0.3s;
      }
    }

    &:hover {
      .small-block__link svg {
        transform: translateX(10px);
      }
    }

    &.full {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      flex: 0 0 100%;
      max-width: 100%;

      .small-block {
        &__bg,
        &__content {
          flex: 0 0 calc(50% - 10px);
          max-width: calc(50% - 10px);
        }

        &__bg {
          order: 1;
        }

        &__content {
          padding-left: 170px;
        }
      }
    }
  }

  .mobile-block {
    display: none;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 50px;

		@include mobile {
			display: block;
		}

    &__img {
      margin-bottom: 15px;
    }

    &__title,
    &__link {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__title {
      margin-bottom: 5px;
      font-size: 22px;
      letter-spacing: 0.62px;
      text-transform: uppercase;
    }

    &__link {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 600;

      .text {
        margin-right: 15px;
      }
    }
  }
}
</style>
