<template lang="pug">
	section.categories-section(:class="{ 'front': isFrontPage === true }")
		template(v-for="(block, index) in section")

			//- Full block
			template(v-if="index === 'full_block'")

				//- Mobile version of full block
				nuxt-link.mobile-block(:to="localePath({ name: 'product-category-slug', params: { slug: replaceUrl(block.link) } }) + '/'")
					img.mobile-block__img(:src="block.bg_mobile"
																:alt="block.title"
																:title="$t('pages.catalog.productImageTitle') + block.title")
					h1.mobile-block__title(v-if="isFrontPage" :style="{ color: block.text_color }") {{ block.title }}
					h2.mobile-block__title(v-else :style="{ color: block.text_color }") {{ block.title }}

				//- Desktop version of full block
				.full-block(:class="{ front: isFrontPage === true }")
					.full-block__bg( :style="{ backgroundImage: `url(${block.bg})` }"
													:class="{ full: isFrontPage === false }")
					h1.full-block__title(v-if="isFrontPage" :style="{ color: block.text_color }") {{ block.title }}
					h2.full-block__title(v-else :style="{ color: block.text_color }") {{ block.title }}

					nuxt-link.full-block__link(:to="localePath({ name: 'product-category-slug', params: { slug: replaceUrl(block.link) } })")
						span.text(:style="{ color: block.text_color }") {{ block.link_text }}
						svg-icon.icon-arrow.icon-arrow--big(name="arrow" :style="{ fill: block.text_color }")

			//- Small block
			template(v-else)

				//- Mobile version of small block
				nuxt-link.mobile-block(:to="localePath({ name: 'product-category-slug', params: { slug: replaceUrl(block.link) } })")
					img.mobile-block__img(:src="block.bg_mobile"
																:alt="block.title"
																:title="$t('pages.catalog.productImageTitle') + block.title")
					span.mobile-block__title {{ block.title }}

				//- Desktop version of small block
				nuxt-link.small-block(:to="localePath({ name: 'product-category-slug', params: { slug: replaceUrl(block.link) } })"
															:class="{ full: index === 'third_block' }")
					.small-block__bg(:style="{ backgroundImage: `url(${block.bg})` }")
					.small-block__content
						span.small-block__title {{ block.title }}
						.small-block__link
							span.text {{ block.link_text }}
							svg-icon.icon-arrow(name="arrow")
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    isFrontPage: {
      type: Boolean,
      default: () => true
    }
  },

  computed: {
    ...mapState({
      section: state => state.content.data.categories_section
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.categories-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
  line-height: 1.35;

  @include laptop {
    margin-bottom: 80px;
  }

  @include tablet {
    margin-bottom: 50px;
  }

  @include mobile {
    margin-bottom: 0;
  }

  &:not(.front) {
    margin-bottom: 80px;

    @include tablet {
      margin-bottom: 50px;
    }

    @include mobile {
      margin-bottom: 30px;
    }
  }

  .full-block {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 250px 0 250px 335px;

    @include laptop {
      padding: 180px 0 180px 50px;
    }

    @include tablet {
      padding: 120px 0;
    }

		@include mobile {
			display: none;
		}

    &__bg {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      right: 50%;
      width: 100vw;
      // max-width: 1920px;
      height: 100%;
      margin-left: calc(-50vw - 100px);
      margin-right: calc(-50vw - 100px);
      background-color: $whiteColor;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include tablet {
        margin-left: -50vw;
        margin-right: -50vw;
      }

      &.full {
        margin-left: -50vw;
        margin-right: -50vw;
      }
    }

    &__title {
      margin-bottom: 24px;
      font-size: 72px;
      font-weight: 700;
      text-transform: uppercase;

      @include laptop {
        font-size: 42px;
      }

      @include tablet {
        margin-bottom: 15px;
        font-size: 40px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 600;

      @include laptop {
        font-size: 20px;
      }

      @include tablet {
        font-size: 18px;
      }

      svg {
        margin-left: 20px;
        fill: $blackColor;
        transition: all 0.3s;
      }

      &:hover {
        svg {
          transform: translateX(10px);
        }
      }
    }

    &.front {
      padding: 250px 0 250px 135px;

      @include laptop {
        padding: 180px 0 180px 50px;
      }

      @include tablet {
        padding: 120px 0;
      }
    }
  }

  .small-block {
    flex: 0 0 100%;
    max-width: calc(50% - 10px);
    margin-top: 120px;

    @include laptop {
      margin-top: 80px;
    }

    @include tablet {
      margin-top: 50px;
    }

		@include mobile {
			display: none;
		}

    &__bg {
      width: 100%;
      height: 700px;
      margin-bottom: 20px;
      background-color: $whiteColor;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include laptop {
        height: 550px;
      }

      @include tablet {
        height: 500px;
      }
    }

    &__title {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__link {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;

      svg {
        margin-left: 20px;
        fill: $blackColor;
        transition: all 0.3s;
      }
    }

    &:hover {
      .small-block__link svg {
        transform: translateX(10px);
      }
    }

    &.full {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      flex: 0 0 100%;
      max-width: 100%;

			@include mobile {
				display: none;
			}

      .small-block {
        &__bg,
        &__content {
          flex: 0 0 100%;
          max-width: calc(50% - 10px);

          @include mobile {
            max-width: 100%;
          }
        }

        &__bg {
          order: 1;
        }

        &__content {
          padding-left: 170px;
        }
      }
    }
  }

  .mobile-block {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 10px;
		display: none;

		@include mobile {
			display: block;
			width: calc(100% + 30px);
			min-height: 135px;
		}

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      position: absolute;
      top: 50%;
      left: 5px;
      right: 5px;
      transform: translateY(-50%);

      color: $whiteColor;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 2px;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      word-wrap: break-word;
    }
  }
}
</style>
